<template>
  <page :has-back-link="false">
    <div class="body--title">
      {{ $t('school.registration.create') }}
    </div>

    <div class="body-content">
      <router-link
        to="/signup/email"
        class="email-button"
      >
        <span>
          {{ $t('school.registration.method_email') }}
        </span>
      </router-link>

      <o-auth-login
        :short="true"
        :region-id="regionId"
        @login="sendOAuthAnalytics($event)"
      />

      <agreement />
    </div>
  </page>
</template>

<script>
  import Page from './Common/Page';
  import Agreement from './Common/Agreement';
  import OAuthLogin from '../../../../laravel/resources/assets/school/components.blocks/OAuthLogin';
  import Analyticable from '../mixins/Analyticable';
  import UsesRegion from '@/mixins/UsesRegion';

  export default {
    name: 'SchoolRegistrationPage',
    components: {
      Page,
      Agreement,
      OAuthLogin,
    },

    mixins: [
      Analyticable,
      UsesRegion,
    ],

    methods: {
      sendOAuthAnalytics(provider) {
        this.sendMarketingData('Lead');
        this.sendMarketingData(`register_${provider}`);
      },
    },
  };
</script>

<style scoped lang="scss">
  @import "../../../../laravel/resources/assets/common/sass/variables";
  @import "../../../../laravel/resources/assets/common/sass/mixins/media-queries";

  .body-content{
    margin: 0 auto;
    max-width: 400px;
  }

  .email-button{
    position: relative;
    background: $theme-grayscale-025;
    border-radius: 4px;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    line-height: 16px;
    padding: $authBtnPadding;
  }

  .email-button:after {
    position: absolute;
    width: 24px;
    height: 24px;
    right: 18px;
    content: '';
    background: url("https://widget.zenclass.ru/images/icons/email.png") center center no-repeat;
    background-size: contain;
  }

  .agreement {
    margin-top: 96px;
  }

  @include mediaXs {
    .body-content{
      max-width: none;
    }

    .email-button{
      padding: $authBtnPaddingXs;
    }
  }

  @include retina {
    .email-button:after{
      background-image: url("https://widget.zenclass.ru/images/icons/email@2x.png");
    }
  }
</style>
