<template>
  <div
    class="z-label"
    :style="style"
  >
    <slot />
  </div>
</template>

<script>
  export default {
    name: 'AppLabel',

    props: {
      size: {
        type: String,
        default: '10px',
      },

      weight: {
        type: String,
        default: '600',
      },
    },

    computed: {
      style() {
        return {
          fontSize: this.size,
          fontWeight: this.weight,
        };
      },
    },
  };
</script>
