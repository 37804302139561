/* Постоянное хранилище на клиенте */

import Storage from './client-storage-localstorage'; // как вариант может быть client-storage-cookie

export default {
  getItem: (key) => {
    const value = Storage.getItem(key);
    let returned = '';
    try {
      returned = JSON.parse(Storage.getItem(key));
    } catch (e) {
      returned = value;
    }

    return returned;
  },

  removeItem: (key) => Storage.removeItem(key),

  setItem: (key, value) => Storage.setItem(key, JSON.stringify(value)),
};
