<script setup>
  import { computed, nextTick, onMounted, ref, useAttrs, watch } from 'vue';
  import { toMoney } from '../js/helpers/number-helper';
  import { useDraft } from '../js/composables/draft';
  import {
    FALLBACK_INPUT_SIZE,
    FALLBACK_INPUT_VARIANT,
    INPUT_SIZE_LARGE,
    INPUT_SIZES,
    INPUT_VARIANT_PLAIN,
    INPUT_VARIANTS,
  } from '../js/constants/input';
  import { ICON_NAMES } from '../js/constants/icon';
  import { convertToUnit } from '../js/helpers/utility';
  import AppIcon from './AppIcon.vue';

  const integerPattern = /^\d{0,15}$/;
  const floatPattern = /^\d{1,14}(\.(\d+)?)?$/;

  const attrs = useAttrs();

  const emits = defineEmits([
    'keydown',
    'update:modelValue',
    'blur',
    'change',
    'paste',
    'focus',
    'ctrlEnter',
    'click:appendInnerIcon',
  ]);

  const props = defineProps({
    /**
     * Содержит значение поля
     */
    modelValue: {
      type: [String, Number],
      default: '',
    },

    /**
     * Устанавливает размер элемента. Можно выбрать только из списка доступных
     */
    size: {
      type: String,
      default: 'medium',
      validator: (value) => INPUT_SIZES.includes(value),
    },

    /**
     * Определяет нужно ли делать автофокус на поле для ввода при его инициализации
     */
    autofocus: {
      type: Boolean,
      default: false,
    },

    /**
     * Добавляет в конец поля для воода кнопку очистки (кнопка видна когда в поле есть содержимое)
     */
    clearable: {
      type: Boolean,
      default: false,
    },

    /**
     * Устанавливает неактивное состояние
     */
    disabled: {
      type: Boolean,
      default: false,
    },

    /**
     * Устанавливает состояние ожидания/загрузки
     */
    loading: {
      type: Boolean,
      default: false,
    },

    /**
     * Устанавливает состояние "только чтение"
     */
    readonly: {
      type: Boolean,
      default: false,
    },

    /**
     * Определяет, является ли поле обязательным
     */
    required: {
      type: Boolean,
      default: false,
    },

    /**
     * Применяет выбранный вариант отображения. Можно выбрать только из списка доступных.
     * @values 'default', 'outline', 'plain', 'inverted'
     */
    variant: {
      type: String,
      default: 'default',
      validator: (value) => INPUT_VARIANTS.includes(value),
    },

    /**
     * Устанавливает имя поля для ввода.
     * Используется в качестве значения атрибута "name", а также для идентификации черновика при использовании draftable
     * (для черновика необходимо задать уникальное в пределах страницы имя)
     */
    name: {
      type: String,
      default: '',
    },

    /**
     * Устанавливает ширину компонента равной 100%
     */
    block: {
      type: Boolean,
      default: false,
    },

    /**
     * Устанавливает максимальное значение счётчика символов.
     * Счётчик отображается при ненулевом значении свойства
     */
    counter: {
      type: [Number, String],
      default: 0,
    },

    /**
     * Массив текстов ошибок
     * @example ["Ошибка 1", "Ошибка 2"]
     */
    errors: {
      type: Array,
      default: () => [],
    },

    /**
     * Устанавливает статичную подсказку, отображаемую под полем для ввода
     */
    hint: {
      type: String,
      default: '',
    },

    /**
     * Устанавливает лейбл поля для ввода
     */
    label: {
      type: String,
      default: '',
    },

    /**
     * Устанавливает максимальную ширину компонента
     * Можно указать число (по умолчанию пиксели) или строку с указанием единиц измерения и без.
     * Примеры: 24, "24", "24px", "50%" и т.д.
     */
    maxWidth: {
      type: [Number, String],
      default: 0,
    },

    /**
     * Устанавливает значение плейсхолдера
     */
    placeholder: {
      type: String,
      default: '',
    },

    /**
     * Определяет, будет ли использоваться функция сохранения черновика.
     * Внимание! Для правильной работы черновика необходимо задать уникальное в пределах страницы
     * значение свойства name
     */
    draftable: {
      type: Boolean,
      default: false,
    },

    /**
     * Запрещает отображение футера, даже при наличии ошибки/подсказки/счётчика
     */
    hideFooter: {
      type: Boolean,
      default: false,
    },

    /**
     * Добавляет иконку с заданным именем в конец поля для ввода.
     * Используется компонент AppIcon, можно выбрать только из списка доступных
     */
    appendInnerIcon: {
      type: String,
      validator: (value) => ICON_NAMES.includes(value),
    },

    /**
     * Добавляет произвольный текст в конец поля для ввода
     */
    appendInner: {
      type: String,
      default: '',
    },

    /**
     * Устанавливает нативный атрибут "pattern" элемента input
     */
    pattern: {
      type: String,
    },

    /**
     * Устанавливает нативный атрибут "type" элемента input
     */
    type: {
      type: String,
      default: 'text',
    },

    /**
     * Устанавливает режим работы с целыми числами.
     * Работает только с типом поля type='text' (он задан по умолчанию)
     * Не работает вместе со свойством float, перебивает его
     */
    integer: {
      type: Boolean,
      default: false,
    },

    /**
     * Устанавливает режим работы с дробными числами.
     * Работает только с типом поля type='text' (он задан по умолчанию)
     * Не работает вместе со свойством integer, перебивается им
     */
    float: {
      type: Boolean,
      default: false,
    },

    /**
     * Устанавливает режим работы с ценами.
     * Работает только с типом поля type='text' (он задан по умолчанию)
     * и только при установке в значение TRUE свойства integer или float
     */
    money: {
      type: Boolean,
      default: false,
    },

    /**
     * Устанавливает выравнивание текста внутри поля для ввода
     */
    inputTextAlign: {
      type: String,
      default: 'left',
      validator: (value) => ['left', 'right', 'center'].includes(value),
    },

    /**
     * Увеличивает толщину текста до полужирного.
     *
     * TODO: это костыль; необходимо наладить консистентность полей для ввода при участии дизайнера,
     * вследствие чего либо удалить костыль, либо доработать, если он будет нужен
     */
    thick: {
      type: Boolean,
      default: false,
    },

    /**
     * Обработчик клика по добавленной в конец поля иконке, при её наличии
     */
    'onClick:appendInnerIcon': {
      type: Function,
      default: null,
    },
  });

  const { getDraftValue, setDraftIfPossible } = useDraft(props.name, props.modelValue);

  const inputVariant = computed(() => (INPUT_VARIANTS.includes(props.variant) ? props.variant : FALLBACK_INPUT_VARIANT));
  const inputSize = computed(() => (INPUT_SIZES.includes(props.size) ? props.size : FALLBACK_INPUT_SIZE));
  const hasAppendInnerIconClickListener = computed(() => !!props['onClick:appendInnerIcon']);

  const focused = ref(false);

  const inputElement = ref(null);
  const moneyElement = ref(null);

  const inputValue = ref(props.modelValue);
  const fallbackInputValue = ref(props.modelValue);

  watch(() => props.modelValue, (value) => {
    /**
     * При обработке float значение inputValue может содержать "float в режиме редактирования" (примеры: "5.", "5.00"),
     * но при преобразовании в число оно будет равно значению modelValue.
     * В таком случае его не нужно обновлять: так пользователь будет видеть "редактируемый" вариант,
     * а modelValue будет содержать преобразованный в число вариант
     */
    const shouldHandleFloatFormat = (props.float && props.type === 'text');
    if (shouldHandleFloatFormat && parseFloat(inputValue.value) === parseFloat(value)) {
      return;
    }

    inputValue.value = value;
  });

  watch(() => inputValue.value, (cur) => {
    fallbackInputValue.value = cur;

    nextTick(() => {
      if (props.draftable) {
        setDraftIfPossible(inputValue.value);
      }

      setValueAsMoneyIfPossible();
    });
  });

  onMounted(() => {
    if (props.draftable) {
      setInputValue(getDraftValue() ?? inputValue.value);
    }

    nextTick(() => {
      if (props.autofocus) {
        focus();
      }

      setValueAsMoneyIfPossible();
    });
  });

  const iconSize = computed(() => (inputSize.value === INPUT_SIZE_LARGE ? 24 : 18));

  const error = computed(() => props.errors.join(', '));
  const charCount = computed(() => (
    typeof inputValue.value === 'string'
      ? inputValue.value.length
      : 0
  ));

  const hasContent = computed(() => (
    inputValue.value === 0
    || inputValue.value
    || props.type === 'date'
  ));

  const rootStyle = computed(() => (
    props.maxWidth
      ? { maxWidth: convertToUnit(props.maxWidth) }
      : {}
  ));

  const setInputValue = (value) => {
    let result = value;

    if (!canControlNumbersFormat.value || !result) {
      emits('update:modelValue', result);
      return;
    }

    if (props.integer) {
      result = result.toString();

      if (integerPattern.test(result)) {
        result = parseInt(result, 10) || 0;
      } else {
        result = fallbackInputValue.value || 0;
      }

      setValueToInputElement(result);
      emits('update:modelValue', result);
      return;
    }

    if (props.float) {
      result = result.toString().replaceAll(',', '.');

      if (!floatPattern.test(result)) {
        result = (fallbackInputValue.value || 0).toString();
      }

      inputValue.value = result;
      setValueToInputElement(result);
      emits('update:modelValue', parseFloat(result));
    }
  };

  const canControlNumbersFormat = computed(() => (props.integer || props.float) && props.type === 'text');
  const canSetValueAsMoney = computed(() => props.money && canControlNumbersFormat.value);

  const setValueAsMoneyIfPossible = () => {
    if (!canSetValueAsMoney.value) {
      return;
    }

    const value = inputElement.value?.value;

    if (!value) {
      return;
    }

    moneyElement.value.innerHTML = toMoney(value.replaceAll(/\s/g, ''));
  };

  const setValueToInputElement = (value) => {
    const cursorPosition = inputElement.value.selectionStart - (+(fallbackInputValue.value === value));
    inputElement.value.value = value;
    inputElement.value.setSelectionRange(cursorPosition, cursorPosition);
  };

  const clear = () => {
    if (props.disabled) {
      return;
    }

    setInputValue('');
  };

  const emitAppendInnerIconClickIfPossible = (event) => {
    if (props.disabled || !hasAppendInnerIconClickListener.value) {
      return;
    }

    emits('click:appendInnerIcon');
    event.stopPropagation();
  };

  const handleBlur = () => {
    focused.value = false;
    setValueAsMoneyIfPossible();
    emits('blur');
  };

  const handleFocus = () => {
    emits('focus');
    focused.value = true;
  };

  const select = () => {
    inputElement.value.select();
  };

  const focus = () => {
    inputElement.value.focus();

    if (attrs.click) {
      inputElement.value.click();
    }
  };

  defineExpose({
    select,
    focus,
  });
</script>

<template>
  <div
    class="z-input"
    :class="{
      'z-input_thick': thick && inputVariant !== INPUT_VARIANT_PLAIN,
      'z-input_block': block,
      'z-input_has-content': hasContent,
      'z-input_has-errors': errors.length,
      'z-input_focused': focused,
      'z-input_disabled': disabled,
      'z-input_loading': loading,
      [`z-input_size_${inputSize}`]: inputSize && inputVariant !== INPUT_VARIANT_PLAIN,
      [`z-input_variant_${inputVariant}`]: inputVariant,
    }"
    :style="rootStyle"
  >
    <div class="z-input__wrap">
      <label
        v-if="label"
        class="z-input__label"
        @click="focus"
      >
        {{ label }}<template v-if="required">*</template>
      </label>

      <div
        class="z-input__group"
        @click="focus"
      >
        <div class="z-input__field-wrap">
          <input
            ref="inputElement"
            class="z-input__field"
            :class="{
              'z-input__field_hidden': canSetValueAsMoney && !focused && inputValue,
              [`z-input__field_text-align_${inputTextAlign}`]: inputTextAlign,
            }"
            :placeholder="focused || !label ? placeholder : ''"
            :disabled="disabled"
            :readonly="readonly"
            :required="required"
            :name="name"
            :data-invalid="!!error"
            :pattern="pattern"
            :type="type"
            :value="inputValue"
            @focus="handleFocus"
            @blur="handleBlur"
            @input="setInputValue($event.target.value)"
            @change="emits('change', inputValue)"
            @paste="emits('paste', $event.clipboardData.getData('text'))"
            @keydown="emits('keydown', $event)"
            @keyup.ctrl.enter="emits('ctrlEnter', $event)"
          >

          <span
            v-if="canSetValueAsMoney"
            ref="moneyElement"
            class="z-input__field z-input__field_money"
            :class="{ 'z-input__field_hidden': focused || !inputValue }"
          />
        </div>

        <app-icon
          v-if="loading"
          class="z-input__icon z-input__icon_spinning"
          :size="iconSize"
          icon="z-cached"
        />

        <app-icon
          v-else-if="clearable && inputValue"
          class="z-input__icon z-input__icon_clickable"
          :size="iconSize"
          icon="z-close"
          @click="clear"
        />

        <app-icon
          v-if="appendInnerIcon"
          class="z-input__icon"
          :class="{ 'z-input__icon_clickable': hasAppendInnerIconClickListener }"
          :size="iconSize"
          :icon="appendInnerIcon"
          @click="emitAppendInnerIconClickIfPossible"
        />

        <span
          v-if="appendInner"
          class="z-input__append-inner"
        >
          {{ appendInner }}
        </span>
      </div>
    </div>

    <div
      v-if="!hideFooter && (hint || +counter || error)"
      class="z-input__footer"
    >
      <div
        v-if="error"
        class="z-input__error"
        v-html="error"
      />

      <div
        v-else-if="hint"
        class="z-input__hint"
        v-html="hint"
      />

      <div
        v-if="counter"
        class="z-input__counter"
        :class="{ 'z-input__counter_warn': charCount > +counter }"
      >
        {{ charCount }} / {{ counter }}
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
  @import "../sass/components.blocks/z-input";

  .z-input__field {
    &_money {
      position: absolute;
      white-space: nowrap;
      overflow: hidden;
      pointer-events: none;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }

    &_hidden {
      opacity: 0 !important;
    }

    $textAlign: left right center;

    @each $item in $textAlign {
      &_text-align_#{$item} {
        text-align: $item;
      }
    }
  }
</style>
