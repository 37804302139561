import marketingEventSend from '../services/MarketingService';

const Analyticable = {
  methods: {
    sendMarketingData(eventName) {
      marketingEventSend(eventName);
    },
  },
};

export default Analyticable;
