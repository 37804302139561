import i18n from '../../../school/js/i18n';

/**
 * @param {string|number|null|undefined} str
 * @param {string} unit
 * @returns {undefined|string}
 */
export const convertToUnit = (str, unit = 'px') => {
  if (!str) {
    return undefined;
  }

  if (isNaN(+str)) {
    return String(str);
  }

  if (!isFinite(+str)) {
    return undefined;
  }

  return `${Number(str)}${unit}`;
};

/**
 * @param bytes
 * @param dec
 * @returns {`${number} ${string}`}
 */
export const adjustSizeUnit = (bytes, dec = 0) => {
  const decimal = 10 ** dec;
  let size = bytes / 1024;
  let unit = 'kb';

  if (size >= 1024) {
    size /= 1024;
    unit = 'mb';
  }

  size = Math.floor(size * decimal) / decimal;

  return `${size} ${i18n.global.t(`file.sizes.${unit}`)}`;
};
