<script setup>
  import { computed, ref } from 'vue';
  import { ICON_NAMES } from '../js/constants/icon';
  import { convertToUnit } from '../js/helpers/utility';

  const props = defineProps({
    /**
     * Устанавливает иконку с указанным именем.
     * Можно выбрать только из списка
     */
    icon: {
      type: String,
      required: true,
      validator: (value) => ICON_NAMES.includes(value),
    },

    /**
     * Устанавливает высоту и ширину иконки.
     * Можно указать число (по умолчанию пиксели) или строку с указанием единиц измерения и без.
     * Примеры: 24, "24", "24px", "1rem" и т.д.
     */
    size: {
      type: [String, Number],
      default: 24,
    },
  });

  const iconElement = ref(null);
  const iconElementStyle = computed(() => {
    const style = {
      width: convertToUnit(props.size),
      height: convertToUnit(props.size),
    };

    try {
      // eslint-disable-next-line global-require, import/no-dynamic-require
      style.maskImage = `url(${require(`../images/icons/${props.icon}.svg`)})`;
    } catch (e) {
      console.log(e);
    }

    return style;
  });
</script>

<template>
  <span
    ref="iconElement"
    class="z-icon"
    :style="iconElementStyle"
  />
</template>

<style lang="scss">
  .z-icon {
    box-sizing: border-box;
    color: inherit;
    background-color: currentColor;
    mask-position: center;
    mask-repeat: no-repeat;
    mask-size: 100%;
    display: inline-flex;
    flex-grow: 0;
    flex-shrink: 0;
    vertical-align: middle;
  }
</style>
