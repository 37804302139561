import { createI18n } from 'vue-i18n';
import i18nEn from '../../lang/en/index';
import i18nRu from '../../lang/ru/index';

const messages = {
  en: i18nEn,
  ru: i18nRu,
};

/**
 * Кастомный вариант окончания слов для русского языка
 * @url https://kazupon.github.io/vue-i18n/guide/pluralization.html#custom-pluralization
 *
 * @param choice {number} a choice index given by the input to $tc: `$tc('path.to.rule', choiceIndex)`
 * @param choicesLength {number} an overall amount of available choices
 * @returns number a final choice index to select plural word by
 * */
function slavicPluralization(choice, choicesLength) {
  if (choice === 0) {
    return 0;
  }

  if (
    choice % 10 === 1
    && choice % 100 !== 11
  ) {
    return 1;
  }

  if (
    choice % 10 >= 2
    && choice % 10 <= 4
    && (choice % 100 < 10 || choice % 100 >= 20)
  ) {
    return 2;
  }

  return (choicesLength < 4) ? 2 : 3;
}

const i18n = createI18n({
  locale: 'ru',
  fallbackLocale: 'ru',
  globalInjection: true,
  messages,
  pluralizationRules: {
    ru: slavicPluralization,
  },
});

export default i18n;
