import getErrors from '../../../common/js/helpers/get-errors';

const ErrorableForm = {
  data() {
    return {
      errorMsg: '',
    };
  },

  methods: {
    resetErrors() {
      this.errorMsg = '';

      for (const key in this.errors) {
        this.errors[key] = [];
      }
    },
    // Объект frontErrors служит для работы с ошибками на фронте. Поля ввода записывают ошибки в него. После попытки отправить
    // идет проверка на наличие ошибок в этом объекте. и при их наличии в объект errors записывается frontErrors.
    // Ошибки сразу не записыватся в errors, чтобы они показывались не в процессе ввода текста, а только после попытки отправить данные.

    resetFrontErrors() {
      for (const key in this.frontErrors) {
        this.frontErrors[key] = [];
      }
    },

    setErrors(err) {
      if (!err.response || !err.response.data) {
        return;
      }

      this.errorMsg = err.response.data.message;
      this.errors = getErrors(err.response.data.errors);
    },
  },
};

export default ErrorableForm;
