<template>
  <button
    v-bind="$attrs"
    class="btn"
    :class="classes"
    @click="$emit('click')"
  >
    <span class="btn-body">
      <slot />
    </span>
  </button>
</template>

<script>
  export default {
    name: 'AppBtn2',

    emits: ['click'],

    props: {
      block: {
        type: Boolean,
        default: false,
      },

      color: {
        type: String,
        default: 'primary',
      },
    },

    computed: {
      classes() {
        return [
          this.block ? 'block' : '',
          this.color,
        ];
      },
    },
  };
</script>

<style scoped lang="scss">
  @import "./../sass/variables";

  .btn{
    border: none;
    border-radius: 4px;
    cursor: pointer;
    display: inline-block;
    font-size: 12px;
    height: 40px;
    padding: 0 24px;
    text-transform: uppercase;
    transition: background-color 0.2s;
  }

  .btn-body{
    white-space: nowrap;
  }

  .primary{
    background-color: $theme-grayscale-900;
    color: #fff;
    font-weight: var(--zen-weight-medium);
  }

  .primary:hover{
    background-color: $theme-grayscale-black;
  }

  .block{
    width: 100%;
  }
</style>
