/*
  Полностью скопированный код ресайза из vuetify (чтобы не было отличий от ранее использованной директивы)
  Ссылка на репозиторий (только файл .ts) - https://github.com/vuetifyjs/vuetify/blob/master/packages/vuetify/src/directives/resize/index.ts
  Версия JS по старому пути - 'vuetify/lib/directives/resize/resize.js'
*/

function mounted(el, binding) {
  let _binding$modifiers;

  const handler = binding.value;
  const options = {
    passive: !((_binding$modifiers = binding.modifiers) != null && _binding$modifiers.active),
  };

  window.addEventListener('resize', handler, options);
  el._onResize = Object(el._onResize);
  el._onResize[binding.instance.$.uid] = {
    handler,
    options,
  };

  const _binding$modifiers2 = binding.modifiers;

  if (!(_binding$modifiers2 != null && _binding$modifiers2.quiet)) {
    handler();
  }
}

function unmounted(el, binding) {
  const _el$_onResize = el._onResize;

  if (!(_el$_onResize != null && _el$_onResize[binding.instance.$.uid])) return;
  const {
    handler,
    options,
  } = el._onResize[binding.instance.$.uid];

  window.removeEventListener('resize', handler, options);
  delete el._onResize[binding.instance.$.uid];
}

export const resize = {
  mounted,
  unmounted,
};
export default resize;
