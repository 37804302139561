/* Постоянное хранилище на клиенте, localstorage */

export default {
  getItem(key) {
    return localStorage.getItem(key);
  },

  removeItem(key) {
    return localStorage.removeItem(key);
  },

  setItem(key, val) {
    return localStorage.setItem(key, val);
  },
};
