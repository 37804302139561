import { nextTick } from 'vue';
import { createRouter, createWebHistory } from 'vue-router';
import SchoolLoginPage from '../components/SchoolLoginPage';
import SchoolRegistrationPage from '../components/SchoolRegistrationPage';
import ResetPasswordPage from '../components/ResetPasswordPage';
import i18n from '../i18n';
import SchoolRegistrationEmailPage from '../components/SchoolRegistrationEmailPage';

const routes = [
  { path: '/signin', component: SchoolLoginPage, meta: { title: i18n.global.t('auth.enter') } },
  { path: '/signup', component: SchoolRegistrationPage, meta: { title: i18n.global.t('auth.registration') } },
  { path: '/signup/email', component: SchoolRegistrationEmailPage, meta: { title: i18n.global.t('auth.registration_email') } },
  { path: '/password-reset', component: ResetPasswordPage, meta: { title: i18n.global.t('password.reset') } },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.afterEach((to) => {
  nextTick(() => {
    if (to.meta.title) {
      document.title = to.meta.title;
    }
  });
});

export default router;
