const marketingEventSend = function marketingEventSend(name) {
  /**
   * Событие (цель) для Яндекс.Метрики
   * 54315594 - zenclass.ru
   * 89273894 - zenclass.ru (отдельный счетчик для эксперимента с трафиком)
   * 75349453 - zenclass.net
   */
  if (window.yaCounter54315594) {
    window.yaCounter54315594.reachGoal(name);
  }

  if (window.yaCounter89273894) {
    window.yaCounter54315594.reachGoal(name);
  }

  if (window.yaCounter75349453) {
    window.yaCounter75349453.reachGoal(name);
  }

  /**
   * Событие для Google Analytics
   */
  if (window.ga && typeof window.ga === 'function') {
    window.ga('send', 'event', 'main', name, '');
  }

  /**
   * Событие для Facebook Pixel
   */
  if (window.fbq && typeof window.fbq === 'function') {
    window.fbq('track', name);
  }
};

export default marketingEventSend;
