import auth from './auth.json';
import labels from './labels.json';
import password from './password.json';
import school from './school.json';

const messages = {
  auth,
  labels,
  password,
  school,
};

export default messages;
