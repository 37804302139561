export const CSV_TYPES = [
  'text/csv',
  'text/plain',
  'application/csv',
  'text/comma-separated-values',
  'application/excel',
  'application/vnd.ms-excel',
  'application/vnd.msexcel',
];

export const DOCUMENT_MAIN_CONTAINER_SELECTOR = '.z-app';

export const DIALOG_SCROLL_LOCK_CLASS = 'mdc-dialog-scroll-lock';
export const DIALOG_OPENED_SELECTOR = '.mdc-dialog--open';
export const DIALOG_CLOSING_CLASS = 'z-dialog--closing';
export const DIALOG_ANIMATED_CLASS = 'animated';

export const PROGRESS_LINEAR = 'linear';
export const PROGRESS_CIRCULAR = 'circular';

/**
 * Константы для сортировки: по возрастанию и по убыванию
 */
export const ORDER_BY_ASC = 'asc';
export const ORDER_BY_DESC = 'desc';

export const LOCAL_STORAGE_DRAFT_KEY = 'draftedFormData';
