<template>
  <div>
    <form @submit.prevent="loginSubmit">
      <div :class="{wrap: $breakpoint.xsOnly}">
        <app-input
          v-model="email"
          type="email"
          :autofocus="$breakpoint.mdAndUp"
          :label="$t('auth.email')"
          :block="$breakpoint.xsOnly"
          :errors="errors.email"
          class="input"
        />

        <app-input-password
          v-model="password"
          :block="$breakpoint.xsOnly"
          :errors="errors.password"
          class="input"
        />

        <div>
          <error-message
            :message="errorMsg"
          />

          <div class="buttons">
            <app-btn
              type="submit"
              :loading="isLoading"
              :disabled="isLoading"
            >
              {{ $t('auth.login') }}
            </app-btn>

            <router-link
              class="reset-link"
              to="/password-reset"
            >
              {{ $t('password.forgot') }}
            </router-link>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
  import i18n from '../../../../../laravel/resources/assets/school/js/i18n';
  import AppInput from '../../../../../laravel/resources/assets/common/components.blocks/AppInput';
  import AppBtn from '../../../../../laravel/resources/assets/common/components.blocks/AppBtn2';
  import login from '../../api/endpoints/login';
  import Redirectable from '../../mixins/Redirectable';
  import ErrorableForm from '../../../../../laravel/resources/assets/school/js/mixins/ErrorableForm';
  import Analyticable from '../../mixins/Analyticable';
  import ErrorMessage from '../../../../../laravel/resources/assets/school/components.blocks/ErrorMessage.vue';
  import AppInputPassword from '../Common/AppInputPassword';

  export default {
    name: 'Login',
    components: {
      AppInputPassword,
      ErrorMessage,
      AppInput,
      AppBtn,
    },

    mixins: [
      Redirectable,
      ErrorableForm,
      Analyticable,
    ],

    data() {
      return {
        email: '',
        password: '',
        isLoading: false,
        errors: {
          email: [],
          password: [],
        },
      };
    },

    methods: {
      loginSubmit() {
        const requiredText = i18n.global.t('event.errors.required');
        if (this.isLoading) {
          return;
        }

        if (this.email === '') {
          if (!this.errors.email.includes(requiredText)) {
            this.errors.email.push(requiredText);
          }

          return;
        }

        if (this.password === '') {
          this.errors.email = this.errors.email.filter((e) => e !== requiredText);
          if (!this.errors.password.includes(requiredText)) {
            this.errors.password.push(requiredText);
          }

          return;
        }

        this.resetErrors();
        this.isLoading = true;
        const data = {
          email: this.email,
          password: this.password,
        };

        login(data)
          .then((response) => {
            this.sendMarketingData('login');
            this.redirect(response);
          })
          .catch(this.setErrors)
          .then(() => {
            this.isLoading = false;
          });
      },
    },
  };
</script>

<style scoped lang="scss">
  @import "../../../../../laravel/resources/assets/common/sass/mixins/media-queries";
  @import "../../../../../laravel/resources/assets/common/sass/_variables";

  .input {
    margin-bottom: 8px;
  }

  .buttons{
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-top: 16px;
  }

  .reset-link{
    display: none;
  }

  @include mediaXs {
    .reset-link{
      display: inline;
      font-size: 12px !important;
      text-transform: uppercase;
    }
  }
</style>
