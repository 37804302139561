<template>
  <div>
    <form @submit.prevent="submit">
      <app-input
        v-model="email"
        type="email"
        :autofocus="$breakpoint.mdAndUp"
        :label="$t('auth.email')"
        :block="$breakpoint.xsOnly"
        :errors="errors.email"
        class="input"
      />

      <app-input
        v-model="owner_phone"
        type="tel"
        pattern="[\s0-9+()-]{6,25}"
        :label="$t('school.registration.owner_phone')"
        :block="$breakpoint.xsOnly"
        :errors="errors.owner_phone"
        class="input"
      />

      <app-input-password
        v-model="password"
        :block="$breakpoint.xsOnly"
        :errors="errors.password"
      />

      <div class="submit-btn-block">
        <error-message
          :message="errorMsg"
        />

        <app-btn
          type="submit"
          :loading="isSchoolRegistering"
          :disabled="isSchoolRegistering || !formValid"
        >
          {{ $t('auth.create_school') }}
        </app-btn>
      </div>
    </form>
  </div>
</template>

<script>
  import AppInput from '../../../../../laravel/resources/assets/common/components.blocks/AppInput';
  import AppBtn from '../../../../../laravel/resources/assets/common/components.blocks/AppBtn2';
  import Redirectable from '../../mixins/Redirectable';
  import ErrorableForm from '../../../../../laravel/resources/assets/school/js/mixins/ErrorableForm';
  import Analyticable from '../../mixins/Analyticable';
  import schoolStore from '../../api/endpoints/school-store';
  import ErrorMessage from '../../../../../laravel/resources/assets/school/components.blocks/ErrorMessage.vue';
  import AppInputPassword from '../Common/AppInputPassword';
  import UsesRegion from '@/mixins/UsesRegion';

  export default {
    name: 'RegisterEmail',
    components: {
      AppInputPassword,
      ErrorMessage,
      AppInput,
      AppBtn,
    },

    mixins: [
      Redirectable,
      ErrorableForm,
      Analyticable,
      UsesRegion,
    ],

    data() {
      return {
        email: '',
        owner_phone: '',
        password: '',
        isSchoolRegistering: false,

        errors: {
          email: [],
          owner_phone: [],
          password: [],
        },
      };
    },

    computed: {
      formValid() {
        return this.email !== '' && this.owner_phone !== '' && this.password !== '';
      },
    },

    methods: {
      submit() {
        if (this.isSchoolRegistering) {
          return;
        }

        this.resetErrors();
        this.isSchoolRegistering = true;
        const data = {
          email: this.email,
          owner_phone: this.owner_phone,
          password: this.password,
        };

        if (this.regionId) {
          data.region_id = this.regionId;
        }

        schoolStore(data)
          .then((response) => {
            this.sendMarketingData('school_create');
            this.sendMarketingData('Lead');
            this.redirect(response);
          }).catch(this.setErrors)
            .then(() => {
              this.isSchoolRegistering = false;
            });
      },
    },
  };
</script>

<style scoped lang="scss">
  @import "../../../../../laravel/resources/assets/common/sass/mixins/media-queries";

  .input {
    margin-bottom: 8px;
  }

  .submit-btn-block{
    margin-top: 40px;
    text-align: center;
  }

  @include mediaXs {
    .submit-btn-block{
      margin-top: 16px;
      text-align: left;
    }
  }
</style>
