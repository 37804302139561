/* Постоянное хранилище на клиенте, cookie */
/* Если понадобится использовать, нужно не забыть отредактировать webpack.mix.js и включить зависимость js-cookie в extract, а также добавить пакет в package.json */

import Cookie from 'js-cookie';

export default {
  getItem(key) {
    return Cookie.get(key);
  },

  removeItem(key, params) {
    return Cookie.remove(key, params);
  },

  setItem(key, val, params) {
    return Cookie.set(key, val, params);
  },
};
