<template>
  <div
    v-if="message"
    class="error-message"
  >
    {{ message }}
  </div>
</template>

<script>
export default {
  name: 'ErrorMessage',

  props: {
    message: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../common/sass/variables";
.error-message{
  color: $theme-systemError;
  font-size: 12px;
  text-align: center;
}
</style>
