import axios from 'axios';
import { computeLocale } from '@/i18n';

const client = axios.create({
  baseURL: process.env.NODE_ENV === 'production' ? `//api.${window.location.hostname}` : '/',
  headers: {
    Locale: computeLocale() || 'en',
    'X-Requested-With': 'XMLHttpRequest',
  },

  withCredentials: true,
});

const request = function request(options) {
  const onSuccess = function onSuccess(response) {
    return response.data;
  };

  const onError = function onError(error) {
    return Promise.reject(error);
  };

  return client(options)
    .then(onSuccess)
    .catch(onError);
};

export default request;
