/*
  Полностью скопированный код ресайза из vuetify (чтобы не было отличий от ранее использованной директивы)
  Ссылка на репозиторий (только файл .ts) - https://github.com/vuetifyjs/vuetify/blob/master/packages/vuetify/src/directives/scroll/index.ts
  Версия JS по старому пути - 'vuetify/lib/directives/scroll/scroll.js'
*/

function mounted(el, binding) {
  const _binding$modifiers = binding.modifiers;

  const {
    self = false,
  } = (_binding$modifiers) != null ? _binding$modifiers : {};

  const value = binding.value;
  const options = (typeof value === 'object' && value.options) || {
    passive: true,
  };

  const handler = typeof value === 'function' || 'handleEvent' in value ? value : value.handler;
  const target = self ? el : binding.arg ? document.querySelector(binding.arg) : window;
  if (!target) return;
  target.addEventListener('scroll', handler, options);
  el._onScroll = Object(el._onScroll);
  el._onScroll[binding.instance.$.uid] = {
    handler,
    options,
    // Don't reference self
    target: self ? undefined : target,
  };
}

function unmounted(el, binding) {
  const _el$_onScroll = el._onScroll;

  if (!(_el$_onScroll != null && _el$_onScroll[binding.instance.$.uid])) return;
  const {
    handler,
    options,
    target = el,
  } = el._onScroll[binding.instance.$.uid];

  target.removeEventListener('scroll', handler, options);
  delete el._onScroll[binding.instance.$.uid];
}

function updated(el, binding) {
  if (binding.value === binding.oldValue) return;
  unmounted(el, binding);
  mounted(el, binding);
}

export const scroll = {
  mounted,
  unmounted,
  updated,
};
export default scroll;
