import { computed, onBeforeUnmount, onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';
import debounce from 'lodash-es/debounce';
import { getDraftValueFromStorage, setDraftToStorage } from '../helpers/draft';

export function useDraft(inputName = '', initialValue = '') {
  const route = useRoute();

  /**
   * Функции черновика активируются при каком-то действии пользователя, чтобы избежать возможности
   * сохранения ненужных черновиков при установке начальных непустых значений полей, загруженных с сервера и т.п.
   */
  const isDraftActivated = ref(false);

  onMounted(() => {
    addDraftActivationListeners();
  });

  const addDraftActivationListeners = () => {
    ['mousedown', 'keydown', 'ontouchstart'].forEach((e) => {
      document.addEventListener(e, activateDraft);
    });
  };

  onBeforeUnmount(() => {
    removeDraftActivationListeners();
    setDraft.cancel();
  });

  const removeDraftActivationListeners = () => {
    ['mousedown', 'keydown', 'ontouchstart'].forEach((e) => {
      document.removeEventListener(e, activateDraft);
    });
  };

  const activateDraft = () => {
    isDraftActivated.value = true;
    removeDraftActivationListeners();
  };

  const draftKey = computed(() => `${route.path}_${inputName}`);

  const setDraftIfPossible = (value) => {
    if (!isDraftActivated.value) {
      return;
    }

    setDraft(value, initialValue);
  };

  const setDraft = debounce((value) => {
    setDraftToStorage(draftKey.value, value, initialValue);
  }, 500);

  const getDraftValue = () => getDraftValueFromStorage(draftKey.value);

  return {
    getDraftValue,
    setDraftIfPossible,
  };
}
