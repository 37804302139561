export default function (data) {
  const errors = {};
  for (const key in data) {
    if (Array.isArray(data[key])) {
      errors[key] = data[key];
    } else {
      errors[key] = [data[key]];
    }
  }

  return errors;
}
