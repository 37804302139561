<template>
  <div class="page body">
    <page-header
      :has-back-link="hasBackLink"
      :link="innerLink"
      class="header"
    />
    <slot />
  </div>
</template>

<script>
  import PageHeader from './PageHeader';

  export default {
    name: 'Page',

    props: {
      link: {
        type: [Object, null],
        default: null,
      },

      hasBackLink: {
        type: Boolean,
        default() {
          return true;
        },
      },
    },

    components: {
      PageHeader,
    },

    computed: {
      innerLink() {
        return this.link || {
          to: '/signin',
          label: this.$t('auth.account.have'),
        };
      },
    },
  };
</script>

<style lang="scss">
  @import "../../../../../laravel/resources/assets/common/sass/variables";
  @import "../../../../../laravel/resources/assets/common/sass/mixins/media-queries";
  @import "../../assets/scss/body-title";

  body {
    min-height: vh(100);
  }

  .page{
    background-size: cover;
    width: 100%;
  }

  .header{
    margin-bottom: 200px;
  }

  .middle {
    margin: 0 auto;
  }

  .body {
    margin: 0 auto;
    padding: 0 20px 40px;
  }

  p {
    font-style: normal;
    font-weight: var(--zen-weight-regular);
    font-size: 14px;
    line-height: 1.3;
    display: flex;
    align-items: center;
    text-align: center;
    color: $theme-text-400;
  }

  .links {
    line-height: 16px;
    text-align: center;
    margin-top: 47px;
  }

  .links a {
    text-transform: uppercase;
    color: $theme-text-default;
    text-decoration: none;
    font-style: normal;
    font-weight: var(--zen-weight-medium);
    font-size: 12px !important;
    cursor: pointer;
  }

  /*
   * Стили инпутов здесь перезаписаны, что является временным костылем. По плану вскоре обновить инпуты и кнопки
   * по всей платформе, что скорее всего приведет либо к единому виду инпутов, либо к разделению на два подвида
   */
  .z-input {
    max-width: 100% !important;
  }

  .z-input__field{
    font-family: inherit;
  }

  .btn {
    display: block;
  }

  .btn[disabled] {
    background: $theme-grayscale-250 !important;
    cursor: not-allowed !important;
  }

  @include mediaXs {
    .links {
      margin-top: 15px;
      text-align: left;
    }

    .header {
      margin-bottom: 100px;
    }
  }

  @include mediaXs {
    .header {
      margin-bottom: 20px;
    }
  }
</style>
