import debounce from 'lodash-es/debounce';

const BREAKPOINTS_DEFAULTS = {
  thresholds: {
    xs: 0,
    sm: 600,
    md: 960,
    lg: 1280,
    xl: 1920,
    xxl: 2560,
  },
};

// Cross-browser support as described in:
// https://stackoverflow.com/questions/1248081
// UPD: не решает проблему кроссбраузерности в Safari, если скроллбар всегда включен
function getClientWidth() {
  if (typeof document === 'undefined') return 0; // SSR
  return Math.max(
    document.documentElement.clientWidth,
    window.innerWidth || 0,
  );
}

function getClientHeight() {
  if (typeof document === 'undefined') return 0; // SSR
  return Math.max(
    document.documentElement.clientHeight,
    window.innerHeight || 0,
  );
}

function isMatchingMedia(value, property = 'max-width') {
  return window.matchMedia(`(${property}: ${value}px)`).matches;
}

const BreakPointMixin = {
  created() {
    this.setDimensions = debounce(function () {
      this.clientHeight = getClientHeight();
      this.clientWidth = getClientWidth();
      this.setBreakpoint();
    }, 200).bind(this);

    if (typeof window === 'undefined') return;

    window.addEventListener('resize', this.setDimensions, { passive: true });
  },

  beforeDestroy() {
    if (typeof window === 'undefined') return;

    window.removeEventListener('resize', this.setDimensions);
  },

  mounted() {
    this.setBreakpoint();
  },

  data() {
    return {
      clientHeight: getClientHeight(),
      clientWidth: getClientWidth(),

      ...BREAKPOINTS_DEFAULTS,
    };
  },

  methods: {
    setBreakpoint() {
      const xs = isMatchingMedia(this.thresholds.sm - 0.02);
      const sm = isMatchingMedia(this.thresholds.md - 0.02) && !xs;
      const md = isMatchingMedia(this.thresholds.lg - 0.02) && !(sm || xs);
      const lg = isMatchingMedia(this.thresholds.xl - 0.02) && !(md || sm || xs);
      const xl = isMatchingMedia(this.thresholds.xxl - 0.02) && !(lg || md || sm || xs);
      const xxl = isMatchingMedia(this.thresholds.xxl, 'min-width');

      const name = xs ? 'xs'
        : sm ? 'sm'
          : md ? 'md'
            : lg ? 'lg'
              : xl ? 'xl'
                : 'xxl';

      Object.assign(this.$breakpoint, {
        // Definite breakpoints.
        xs,
        sm,
        md,
        lg,
        xl,
        xxl,

        // Breakpoint ranges.
        smAndUp: !xs,
        mdAndUp: !(xs || sm),
        lgAndUp: !(xs || sm || md),
        xlAndUp: !(xs || sm || md || lg),
        smAndDown: !(md || lg || xl || xxl),
        mdAndDown: !(lg || xl || xxl),
        lgAndDown: !(xl || xxl),
        xlAndDown: !xxl,

        // Useful e.g. to construct CSS class names dynamically.
        name,

        // For custom breakpoint logic.
        width: this.clientWidth,
        height: this.clientHeight,
        thresholds: this.thresholds,
      });
    },
  },
};

export default BreakPointMixin;
