import { createApp, h, reactive } from 'vue';
import App from './App.vue';
import router from './router/routes';
// eslint-disable-next-line import/no-named-as-default,import/no-named-as-default-member
import BreakPointMixin from '../../../laravel/resources/assets/common/js/mixins/breakpoint';
import i18n, { computeLocale } from './i18n';
import Resize from '../../../laravel/resources/assets/common/js/directives/resize';
import Scroll from '../../../laravel/resources/assets/common/js/directives/scroll';

const app = createApp({
  render() {
    return h(App);
  },

  mixins: [BreakPointMixin],
  mounted() {
    /**
     * Реактивная смена локали приложения
     *
     * Актуально исключительно для dev и staging сред, чтобы было попроще тестировать виджет
     * на разных языках. Но при необходимости можно сгенерировать соответствующее событие
     * и в проде - язык UI изменится корректно
     */
    document.addEventListener('localeChange', () => {
      i18n.global.locale = computeLocale();
    });
  },
});

app.config.productionTip = false;
app.config.globalProperties.$breakpoint = reactive({});
app.use(i18n);
app.use(router);
app.directive('resize', Resize);
app.directive('scroll', Scroll);

router.isReady().then(() => {
  app.mount('#app');
});
