import ClientStorage from '../client-storage';
import { LOCAL_STORAGE_DRAFT_KEY } from '../constants';

const getDrafts = () => ClientStorage.getItem(LOCAL_STORAGE_DRAFT_KEY) || {};

const getDraftValueFromStorage = (key) => getDrafts()[key];

const setDraftToStorage = (key, value, initialValue) => {
  const drafts = getDrafts();

  if (value && value !== initialValue) {
    drafts[key] = value;
  } else {
    delete drafts[key];
  }

  ClientStorage.setItem(LOCAL_STORAGE_DRAFT_KEY, drafts);
};

const removeDraftFromStorage = (key) => {
  setDraftToStorage(key, '', '');
};

export {
  getDraftValueFromStorage,
  setDraftToStorage,
  removeDraftFromStorage,
};
