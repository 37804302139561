<template>
  <page
    :has-back-link="false"
    :link="{to: '/signup', label: $t('auth.account.no')}"
  >
    <div class="content">
      <div class="body--title">
        {{ $t('school.login') }}
      </div>

      <div class="layout wrap">
        <div class="flex xs12 sm6 classic-login">
          <div class="block-wrapper">
            <login />
          </div>
        </div>

        <div class="flex xs12 sm6">
          <div class="block-wrapper">
            <app-label
              size="14px"
              weight="500"
              class="oauth-label"
            >
              {{ $t('auth.continue_through') }}
            </app-label>

            <o-auth-login
              only-name
              :region-id="regionId"
              @login="sendOAuthAnalytics($event)"
            />
          </div>
        </div>
      </div>

      <div class="links">
        <router-link to="/password-reset">
          {{ $t('password.forgot') }}
        </router-link>
      </div>
    </div>
  </page>
</template>

<script>
  import Page from './Common/Page';
  import Login from './Forms/Login';
  import AppLabel from '../../../../laravel/resources/assets/common/components.blocks/AppLabel';
  import OAuthLogin from '../../../../laravel/resources/assets/school/components.blocks/OAuthLogin';
  import Analyticable from '../mixins/Analyticable';
  import UsesRegion from '@/mixins/UsesRegion';

  export default {
    name: 'SchoolLoginPage',
    components: {
      AppLabel,
      Page,
      Login,
      OAuthLogin,
    },

    mixins: [
      Analyticable,
      UsesRegion,
    ],

    methods: {
      sendOAuthAnalytics(provider) {
        this.sendMarketingData('Lead');
        this.sendMarketingData(`login_${provider}`);
      },
    },
  };
</script>

<style scoped lang="scss">
  @import "../../../../laravel/resources/assets/common/sass/variables";
  @import "../../../../laravel/resources/assets/common/sass/mixins/media-queries";
  @import "../../src/assets/scss/body-title";

  $border: 1px solid $theme-grayscale-250;

  .content {
    margin: 0 auto;
    max-width: 800px;
  }

  .classic-login {
    border-right: $border;
    align-items: center;
    display: flex;
  }

  .block-wrapper {
    margin: 0 auto;
    max-width: 80%;
    width: 100%;
  }

  .oauth-label{
    margin-bottom: 12px;
  }

  @include mediaXs {
    .classic-login {
      border-bottom: $border;
      border-right: none;
      margin-bottom: 24px;
      padding-bottom: 12px;
    }

    .block-wrapper {
      max-width: 100%;
    }

    .links{
      display: none;
    }
  }
</style>
