export const INPUT_SIZE_SMALL = 'small';
export const INPUT_SIZE_MEDIUM = 'medium';
export const INPUT_SIZE_LARGE = 'large';

export const INPUT_SIZES = [
  INPUT_SIZE_SMALL,
  INPUT_SIZE_MEDIUM,
  INPUT_SIZE_LARGE,
];

export const FALLBACK_INPUT_SIZE = INPUT_SIZE_MEDIUM;

export const INPUT_VARIANT_DEFAULT = 'default';
export const INPUT_VARIANT_OUTLINE = 'outline';
export const INPUT_VARIANT_PLAIN = 'plain';
export const INPUT_VARIANT_INVERTED = 'inverted';

export const INPUT_VARIANTS = [
  INPUT_VARIANT_DEFAULT,
  INPUT_VARIANT_OUTLINE,
  INPUT_VARIANT_PLAIN,
  INPUT_VARIANT_INVERTED,
];

export const FALLBACK_INPUT_VARIANT = INPUT_VARIANT_DEFAULT;
