import ClientStorageCookie from '../../../../laravel/resources/assets/common/js/client-storage-cookie';
import CONSTANTS from '../../../../laravel/resources/assets/school/js/constants';
import hostName from '../services/DomainService';

const Redirectable = {
  methods: {
    redirect(response) {
      ClientStorageCookie.setItem(CONSTANTS.LOCALSTORAGE_ACCESS_TOKEN, response.access_token, { domain: hostName, expires: 1 });
      ClientStorageCookie.setItem(CONSTANTS.LOCALSTORAGE_USER_ID, response.user_id, { domain: hostName, expires: 1 });

      let redirectTo = response.redirect_to;
      if (!redirectTo) {
        redirectTo = `${response.domain}/start`;
      }

      window.location = `//${redirectTo}`;
    },
  },
};

export default Redirectable;
