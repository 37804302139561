const UsesRegion = {
  data() {
    return {
      zenOptions: window.zenOptions || {},
    };
  },

  computed: {
    regionId() {
      return this.zenOptions.regionId || null;
    },
  },
};

export default UsesRegion;
