<template>
  <page :link="{}">
    <div class="body--title">
      {{ $t('password.recovery') }}
    </div>

    <p
      class="description"
      v-html="$t('password.reset_password_message')"
    />

    <div class="reset-password-wrapper">
      <reset-password />
    </div>

    <div class="links">
      <router-link to="/signin">
        {{ $t('password.remember') }}
      </router-link>
    </div>
  </page>
</template>

<script>
  import Page from './Common/Page';
  import ResetPassword from './Forms/ResetPassword';

  export default {
    name: 'ResetPasswordPage',
    components: {
      Page,
      ResetPassword,
    },
  };
</script>

<style scoped lang="scss">
  @import "../../src/assets/scss/body-title";
  @import "../../../../laravel/resources/assets/common/sass/mixins/media-queries";

  @include mediaSmAndUp {
    .body--title {
      margin-bottom: 20px;
    }
  }

  .description{
    margin: 0 auto;
    max-width: 440px;
  }

  .reset-password-wrapper {
    max-width: 208px;
    margin: 32px auto 0 auto;
  }

  @include mediaXs {
    .description{
      display: none;
    }

    .reset-password-wrapper {
      max-width: none;
      margin: auto;
    }
  }
</style>
